import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { Icon, Layout, PageSection, SEO } from "../components"

import { navigate } from "@reach/router"

const SharePage = ({ location }) => {
  const { state } = location
  const [copiedText, setCopiedText] = useState("")

  const shareURL = `danfurzeadventur.es/${state && state.url ? state.url : ""}`

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Layout>
      <>
        <SEO />
        <PageSection
          id="share"
          title="Thanks for wanting to share!"
          theme="mid"
          variant="left"
        ></PageSection>

        <PageSection theme="light" id="main-content" variant="left">
          <p>I don’t have any fancy social media sharing buttons.</p>
          <p>
            It’d waste my time building them and waste your bandwidth loading
            them.
          </p>
          <p>
            Instead, please copy this short URL for the article you want to
            share, and stick it on your social media weapon of choice.
          </p>

          <label>
            <input autoFocus={true} type="url" value={shareURL} />
            <CopyToClipboard
              text={shareURL}
              onCopy={() => setCopiedText("Copied successfully!")}
            >
              <button>Copy to clipboard</button>
            </CopyToClipboard>{" "}
            <small>{copiedText}</small>
          </label>

          <p>
            <small>
              <Icon name="arrow-left" fill="#B7B7B7" />{" "}
              <a href="#" onClick={goBack} className="back-link">
                Back
              </a>
            </small>
          </p>
        </PageSection>
      </>
    </Layout>
  )
}

export default SharePage
